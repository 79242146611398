export const CORE_DASHBOARD = "/:username/dashboard";
export const CORE_SOURCES = "/:username/sources";
export const CORE_BOL = "/:username/bol";
export const CORE_ADMIN = "/:username/admin";
export const CORE_FAQ = "/:username/faq";
export const CORE_NOTIFICATIONS = "/:username/notifications";
export const CORE_AUDITS = "/:username/audit";
export const CORE_FACILITIES = "/:username/facilities";
export const CORE_RELEASE_NOTES = "/:username/release-notes";
export const CORE_APIV2 = "/:username/apiv2";
