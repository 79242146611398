import api from "./api";

const sendEmail = async (email, encodedBase64) => {
  const data = {
    sendUserEmail: 1,
    to: email,
    activationUrl: `confirm-signup/${encodedBase64}`,
  };

  const res = await api.post("/", data).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });

  return res;
};

const encodeBase64 = (data) => btoa(JSON.stringify(data));

const decodeBase64 = (data) => JSON.parse(atob(data));

const EmailService = {
  sendEmail,
  encodeBase64,
  decodeBase64,
};
export default EmailService;
