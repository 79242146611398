import api from "./api";
import StorageService from "./storage.service";

const login = async (credentials) => {
  const { userName, password } = credentials;

  const data = {
    globalSignIn: 1,
    uname: userName,
    upsw: password,
  };

  const res = await api.post("/", data).catch((error) => {
    console.error("An error occurred:", error);
    // return error.response;
  });

  return res;
};

const registerAuditor = async (data, code) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { firstName, lastName, confirmPwd, ...rest } = data;

  const name = firstName + " " + lastName;
  let updatedResData = {
    ...rest,
    name,
    globalSignUp: 1,
    code: code ? code : "",
  };

  const res = await api.post("/", updatedResData).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });

  return res;
};

const confirmSignUp = async (data) => {
  const payloadData = { ...data, globalConfirmSignUp: 1 };

  const res = await api.post("/", payloadData).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });

  return res;
};

const logout = async () => {
  const sid = StorageService.getSid();
  const { ms_sso } = StorageService.getUser();
  if (ms_sso === "true") {
    StorageService.removeUser();
    return (window.location.href =
      "https://login.microsoftonline.com/organizations/oauth2/v2.0/logout");
  } else {
    const payloadData = { sid, globalSignOut: 1 };
    await api.post("/", payloadData).catch((error) => {
      console.error("An error occurred:", error);
      throw error;
    });
    StorageService.removeUser();
    window.location.pathname = "/login";
  }
};

const getCurrentUser = () => {
  return StorageService.getUser();
};

const isLogin = () => {
  const { name, sid, role_id } = getCurrentUser();
  return name && sid && role_id;
};

const forgotPassword = async (data) => {
  const payloadData = { ...data, globalPasswordReset: 1 };

  const res = await api.post("/", payloadData).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });

  return res;
};

const loginWithMicrosoft = async (data) => {
  const res = await api.post("/login-with-microsoft", data).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });
  return res;
};

const postMicrosoftLogin = async (data) => {
  const { state, ...rest } = data;
  let user_state = state?.split("}")[0];
  const payload = { ...rest, state: user_state, msalSignIn: 1 };

  const res = await api.post("/login-micro", payload).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });
  return res;
};

const postMicrosoftLoginV2 = async () => {
  const sid = StorageService.getSid();

  const payload = { sid: sid, ApiV2: 1 };
  const res = await api.post("/login-micro", payload).catch((error) => {
    console.error("An error occurred:", error);
    throw error;
  });
  return res;
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  confirmSignUp,
  isLogin,
  registerAuditor,
  forgotPassword,
  loginWithMicrosoft,
  postMicrosoftLogin,
  postMicrosoftLoginV2,
};
export default AuthService;
