import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmailService from "../../services/email.service";
import AuthService from "../../services/auth.service";
import { errorNotification, successNotification } from "../../globals/helpers";

function ConfirmSignup() {
  const { base64 } = useParams();
  const navigate = useNavigate();
  const [isConfirm, setConfirm] = useState(false);
  const [isError, setError] = useState(null);

  const verifyUser = async () => {
    if (base64) {
      try {
        const decodedBase64 = EmailService.decodeBase64(base64);
        const { status } = await AuthService.confirmSignUp(decodedBase64);
        if (status === 200) {
          setConfirm(true);
          successNotification("User is Verified");
        }
      } catch (error) {
        setError(error);
        if (error?.response?.status === 400) {
          navigate("/login");
          return;
        }

        errorNotification("Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (!isConfirm) {
      verifyUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!base64) {
    return <h4>Invalid Url</h4>;
  }

  return (
    <div className="container mt-5 p-2">
      {isConfirm ? (
        <h4>
          User is verified now go to <Link to="/login">login page</Link>
        </h4>
      ) : isError ? (
        <div>Something went wrong</div>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}

export default ConfirmSignup;
