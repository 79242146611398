import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import StorageService from "../services/storage.service";

export const successNotification = (message) =>
  toast.success(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const errorNotification = (message) =>
  toast.error(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const warningNotification = (message) =>
  toast.warning(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const findMaxPowersOfTwo = (number) => {
  let powers = [];
  let power = Math.floor(Math.log2(number));

  while (number > 0) {
    if (Math.pow(2, power) <= number) {
      powers.push(Math.pow(2, power));
      number -= Math.pow(2, power);
    }
    power--;
  }

  if (powers[powers.length - 1] === 1) {
    powers.pop();
  }

  return powers;
};

function maxNumbersBelowTarget(target, numbers) {
  numbers.sort((a, b) => b - a);

  let result = [];
  let total = 0;

  for (let num of numbers) {
    if (total + num <= target) {
      result.push(num);
      total += num;
    }
  }

  return result;
}

export const extractRolesFromRoleId = (roleId, allRoles = []) => {
  // const possibleRoles = findMaxPowersOfTwo(roleId);
  let allRoleIds = allRoles.map((role) => role?.id);
  // return possibleRoles.filter((role) => allRoleIds?.includes(role));

  return maxNumbersBelowTarget(roleId, allRoleIds);
};

export const generatePathUrl = (coreUrl, contributor_user_name) => {
  const { name, contributor_name } = StorageService.getUser();

  const url = contributor_user_name || contributor_name || name || "username";

  return generatePath(coreUrl, {
    username: url?.replace(/\s/g, "")?.toLowerCase(),
  });
};
