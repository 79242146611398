import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
  useParams,
} from "react-router-dom";
import AuthService from "./services/auth.service";
import StorageService from "./services/storage.service";
import {
  CORE_ADMIN,
  CORE_APIV2,
  CORE_AUDITS,
  CORE_BOL,
  CORE_DASHBOARD,
  CORE_FACILITIES,
  CORE_FAQ,
  CORE_NOTIFICATIONS,
  CORE_RELEASE_NOTES,
  CORE_SOURCES,
} from "./globals/urls";
import ConfirmSignup from "./pages/ConfirmSignup/ConfirmSignup";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Login = React.lazy(() => import("./pages/Login/Login"));
const Register = React.lazy(() => import("./pages/Register/Register"));
const Admin = React.lazy(() => import("./pages/Admin/Admin"));
const App = React.lazy(() => import("./App"));
const ForgotPassword = React.lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword")
);
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Bol = React.lazy(() => import("./pages/Bol/Bol"));
const Sources = React.lazy(() => import("./pages/Sources/Sources"));
const FAQ = React.lazy(() => import("./pages/FAQ/Faq"));
const Audit = React.lazy(() => import("./pages/Audit/Audit"));
const Facility = React.lazy(() => import("./pages/Facilities/Facilities"));
const Notification = React.lazy(() =>
  import("./pages/Notifications/Notifications")
);
const ApiV2 = React.lazy(() => import("./pages/Apiv2/Apiv2"));

const Entracallback = React.lazy(() =>
  import("./pages/Entracallback/Entracallback")
);
const ReleaseNotePage = React.lazy(() =>
  import("./pages/ReleaseNotes/ReleaseNotes")
);

const AppRouter = () => {
  const isDevelopment = process.env.REACT_APP_NODE_ENV === "development";

  return (
    <div>
      {isDevelopment ? (
        <div className="progress">
          <div className="progress-bar w-100">Stg Server</div>
        </div>
      ) : null}
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 60, color: "#549a0b" }}
                  spin
                />
              }
            />
            <b
              style={{
                fontSize: 20,
              }}
            >
              Loading...
            </b>
          </div>
        }
      >
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/confirm-signup/:base64" element={<ConfirmSignup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/entracallback" element={<Entracallback />} />
            <Route element={<PrivateWrapper />}>
              <Route path={CORE_DASHBOARD} element={<Dashboard />} />
              <Route path={CORE_APIV2} element={<ApiV2 />} />
              <Route path={CORE_SOURCES} element={<Sources />} />
              <Route path={CORE_BOL} element={<Bol />} />
              <Route path={CORE_ADMIN} element={<Admin />} />
              <Route path={CORE_FAQ} element={<FAQ />} />
              <Route path={CORE_NOTIFICATIONS} element={<Notification />} />
              <Route path={CORE_AUDITS} element={<Audit />} />
              <Route path={CORE_FACILITIES} element={<Facility />} />
              <Route path={CORE_RELEASE_NOTES} element={<ReleaseNotePage />} />
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

const PrivateWrapper = () => {
  const isAuthenticated = AuthService.isLogin();
  const { username } = useParams();

  const { contributor_name, name } = StorageService.getUser();

  if (
    username !== contributor_name?.replace(/\s/g, "")?.toLowerCase() &&
    username !== name?.replace(/\s/g, "")?.toLowerCase()
  ) {
    return <Navigate to="/login" />;
  }

  return isAuthenticated ? (
    <App>
      <Outlet />
    </App>
  ) : (
    <Navigate to="/login" />
  );
};

export default AppRouter;
